import React from 'react';
import HeaderWH from '../Header_WH/HeaderWH';
import FooterWH from '../Footer_WH/FooterWH';
import SplashScreenWH from '../SplashScreen_WH/SplashScreenWH';

const HomeWH = () => {
    return (
        <div className='text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
            <SplashScreenWH/>
            <HeaderWH/>

            <div className='px-2 md:px-5'>
                This is home page
            </div>
            
            <FooterWH/>
        </div>
    );
};

export default HomeWH;