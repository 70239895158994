import React, { useEffect } from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import { motion as m } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { All_Projects } from '../../../Config';
// import { IoIosArrowForward } from "react-icons/io";

const CaseStudy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  const { projectName } = useParams();
  const project = All_Projects.find(p => p.name === projectName); 

  if (!project) {
    return <div>Projects not found</div>; 
  }

  const img1 = project.images[0];
  const img2 = project.images[1];
  const img3 = project.images[2];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 0.5, 1] }}
      transition={{ duration: 0.9, ease: 'easeIn' }}
      style={{ willChange: 'opacity' }}
    >
      <div className='bg-white dark:bg-black dark:text-white  text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
        <HeaderIT />
        <br />
        <div className='px-2 md:px-5 lg:mt-20 2xl:mt-28'>
          <div className='mt-16 md:mt-24 2xl:mt-10 mx-4 md:mx-10 lg:mx-20 2xl:mx-24'>
            {/* Project name */}
            <div className='flex justify-between'>
              <p className='project_name font-bold text-4xl md:text-5xl 2xl:text-6xl'>
                {project.name}
              </p>

              {/* <button className='font-light flex items-center hover:text-[#008080]'>
                Next project
                <IoIosArrowForward className="h-4 w-4 ml-1 2xl:h-6 2xl:w-6 2xl:mt-0.5 group-hover:inline-block" />
              </button> */}
            </div>

            {/* Project features */}
            <div className='grid grid-cols-2 md:flex items-center text-center gap-2 text-xs 2xl:text-lg my-[2%]'>
              {project.features.map((feature, featureIndex) => (
                <p
                  key={featureIndex}
                  className={`bg-opacity-65 text-white py-1 px-3 rounded-full 
                  ${feature === 'Website' || feature === 'E-commerce Website'
                    ? 'bg-[#008080]'
                    : feature === 'Admin Panel' || feature === 'Web-app'
                      ? 'bg-[#635BFF]'
                      : feature === 'Management Services'
                        ? 'bg-[#181885]'
                        : feature === 'SEO'
                          ? 'bg-[#4F9CF9]'
                          : feature === 'Content Writing'
                            ? 'bg-[#94A4BF]'
                            : ''
                    }`}
                >
                  {feature}
                </p>
              ))}
            </div>

            <div className='grid grid-cols-1 lg:grid-cols-2'>
              <div>
                <div className='grid grid-cols-2'>
                  <div>
                    {/* Timeframe */}
                    <p className='mt-[5%]'>Timeframe:</p>
                    <p>
                      <b>{project.timeframe}</b>
                    </p>

                    {/* Role */}
                    <p className='mt-[2%]'>Init Technology Role:</p>
                    <p className='w-[85%]'>
                      <b>{project.role}</b>
                    </p>
                  </div>

                  {/* Technologies */}
                  <div className='mt-[7%]'>
                    <div className='absolute bg-black h-[6.3em] w-[1px]'></div>
                    <div className='font-light text-xs 2xl:text-lg ml-2'>
                      {project.technologies.map((tech, techIndex) => (
                        <p key={techIndex}>{tech}</p>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Project description */}
                <div className='mt-[5%]'>
                  <p>{project.description}</p>
                </div>
              </div>

              {/* Project logo */}
              <div className='mt-[5%] lg:mt-0'>
                <div className='bg-white shadow-circle h-[18em] w-[18em] flex mx-auto lg:mr-[0%] items-center rounded-full p-[5%] hover:scale-110 overflow-hidden transition-transform duration-300 ease-in-out'>
                  <img src={project.logo} alt="Img" />
                </div>
              </div>
            </div>

            {/* Project images */}
            <div className='flex flex-col lg:flex-row mt-[8%] lg:mt-[5%] gap-5 lg:gap-0 lg:mx-[8%]'>
              <div className='bg-[#f9f9f9] p-[5%] lg:p-[2%] h-[100%] w-[100%] lg:h-[140%] lg:w-[140%] rounded-3xl'>
                <img src={img1} alt="Img" />
              </div>

              <div className='bg-[#f9f9f9] p-[5%] lg:p-[2%] h-[85%] w-[85%] lg:h-[120%] lg:w-[120%] lg:mt-[15%] ml-[8%] lg:-ml-[10%] rounded-3xl'>
                <img src={img2} alt="Img" />
              </div>

              <div className='bg-[#f9f9f9] p-[5%] lg:p-[2%] h-[70%] w-[70%] lg:h-[100%] lg:w-[100%] lg:mt-[22%] ml-[15%] lg:-ml-[10%] rounded-3xl'>
                <img src={img3} alt="Img" />
              </div>
            </div>

            {/* Circle and text section */}
            <div className='grid grid-cols-1 lg:grid-cols-2 items-center mt-[8%] lg:mt-[5%]'>
              <div>
                <div className='bg-[#f9f9f9] dark:bg-[#252525] rounded-full flex flex-col items-center justify-center w-[12em] h-[12em] md:text-lg lg:text-[16px] 2xl:text-2xl'>
                  <div className=''>
                    <p>Link:</p>
                    <a
                      href={project.link}
                      target='_blank'
                      rel='noreferrer'
                      className='hover:underline'
                    >
                      <b>Visit Website</b>
                    </a>

                    <p>Contact:</p>
                    <a
                      href='tel:+919664570369'
                      target='_blank'
                      rel='noreferrer'
                      className='hover:underline'
                    >
                      <b>Let's Collaborate</b>
                    </a>
                  </div>
                </div>
              </div>

              <div className='mt-[5%] lg:mt-0 lg:-ml-[30%]'>
                <p>{project.description2}</p>
              </div>
            </div>
          </div>
        </div>
        <FooterIT />
      </div>
    </m.div>
  );
};

export default CaseStudy;