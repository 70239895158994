import React, { useEffect } from "react";
import './SplashScreenIT.css';

const SplashScreen = () =>{

    useEffect(() => {
        
        var splashScreen = document.querySelector('.splash_IT');
        splashScreen.addEventListener('click', () => {
          splashScreen.style.opacity = 0;
          setTimeout(() => {
            splashScreen.classList.add('hidden_IT');
          }, 610);
        });
      }, []); 

    return(
        <div>
          <div className="splash_IT">
            <h1 className= "splash-header_IT">
              <img className="logo_SplashScreen_IT" src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e95f5ed6-205b-404c-2a51-6872d868ff00/public" alt="logo"></img> {/* init_INIT.webp */}
            </h1>
          </div>
        </div>
    );
};

export default SplashScreen;