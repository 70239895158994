import React from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import { motion as m } from 'framer-motion';

const OurTeam = () => {

    const teamMembers = [
        {
          name: 'Mr. Sikandar Bathvar',
          role: 'Director',
          imgSrc: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/af2689e2-1d7b-48f6-e084-9ba7fc5d8a00/public', //init_sikandar_avatar.webp
          linkedInUrl: 'https://www.linkedin.com/in/sikandar-bathvar-5a2b46154/',
        },
        {
          name: 'Ms. Naju Keshwala',
          role: 'Director',
          imgSrc: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9da09afe-1b7b-4483-1597-79f342994900/public', //init_naju_avatar.webp
          linkedInUrl: 'https://www.linkedin.com/in/naju-keshwala-37075912a/',
        },
        {
          name: 'Ms. Areebah Kadri',
          role: 'Front-end Developer',
          imgSrc: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/bd8fcd3c-361d-45ac-dbc1-9d99fc363300/public', //init_areebah_avatar.webp
          linkedInUrl: 'https://www.linkedin.com/in/areebah-k-059150244/',
        },
      ];

    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
                <HeaderIT />
                <br />
                <div className='px-2 md:px-5 lg:mt-20 2xl:mt-28'>

                    <div className='flex flex-col items-center justify-center my-12 md:my-14'>
                        <div className='h-32 md:h-40 lg:h-48 2xl:h-72 w-full lg:w-[88vw] lg:mx-16 2xl:mx-48 z-0 rounded-xl overflow-hidden'>
                            <img className='h-full w-full' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0a699c4d-3e81-4e13-970c-920a7cea9b00/public" alt="background" /> {/* init_background.webp */}
                        </div>
                        <div className='flex items-center justify-center h-32 md:h-40 lg:h-48 2xl:h-72 -mt-32 md:-mt-40 lg:-mt-48 2xl:-mt-72 z-0'>
                            <p className='text-black text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold'>
                                Our Team
                            </p>
                        </div>
                    </div>

                    <div className='mt-10 mx-4 md:mx-10 lg:mx-36 2xl:mx-68'>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between'>
                            {teamMembers.map((member, index) => (
                                <div key={index}>
                                    <div className='bg-white dark:bg-[#252525] p-3 lg:p-5 lg:py-6 2xl:p-8 mx-[5%] m-2 md:m-5 lg:m-6 2xl:m-12 rounded-xl shadow-lg hover:shadow-custom hover:scale-105 transition-transform duration-300 ease-in-out'>
                                        <div>
                                            <img className='w-full' src={member.imgSrc} alt={`${member.name}-Img`} />
                                        </div>

                                        <div className='mt-1 lg:mt-2 2xl:mt-4'>
                                            <p className='font-semibold leading-6'>{member.name}</p>
                                            <p className='text-sm 2xl:text-lg mt-2 lg:mt-1 2xl:mt-2'>{member.role}</p>

                                            <div className='flex justify-end mt-2 md:mt-0'>
                                                <a href={member.linkedInUrl} target='_blank' rel='noreferrer'>
                                                    <img className='h-5 w-5 md:h-6 md:w-6 2xl:h-10 2xl:w-10 hover:scale-110' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e548aea1-298b-440e-7f6d-f5630aac4c00/public" alt="LinkedIn Icon" /> {/* init_linkedin_icon.webp */}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                </div>
                <FooterIT />
            </div>
        </m.div>
    );
};

export default OurTeam;