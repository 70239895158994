import React from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import {motion as m} from 'framer-motion';

const TermsConditions = () => {
    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
                <HeaderIT />
                <br />
                <div className='px-2 md:px-5 lg:mt-20 2xl:mt-28'>
                    
                    <div className='flex flex-col items-center justify-center my-12 md:my-14'>
                        <div className='h-32 md:h-40 lg:h-48 2xl:h-72 w-full lg:w-[88vw] lg:mx-16 2xl:mx-48 z-0 rounded-xl overflow-hidden'>
                            <img className='h-full w-full' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0a699c4d-3e81-4e13-970c-920a7cea9b00/public" alt="background" /> {/* init_background.webp */}
                        </div>
                        <div className='flex items-center justify-center h-32 md:h-40 lg:h-48 2xl:h-72 -mt-32 md:-mt-40 lg:-mt-48 2xl:-mt-72 z-0'>
                            <p className='text-black text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold'>
                                Terms & Conditions
                            </p>
                        </div>
                    </div>

                    <div className='mt-10 mx-4 md:mx-10 lg:mx-36 2xl:mx-72'>
                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>1. Use of the Website</p>
                            <p>&nbsp; 1.1. You agree to use the Website only for lawful purposes and in accordance with these Terms.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>2. Intellectual Property</p>
                            <p>&nbsp; 2.1. All content and materials available on the Website, including but not limited to text, graphics,
                                logos, images, and software, are the property of Init Technology or its licensors and are protected by
                                copyright, trademark, and other intellectual property laws.</p>

                            <p className='mt-2 lg:mt-4'>&nbsp; 2.2. You may not modify, reproduce, distribute, transmit, display, perform, or create derivative works
                                of any content or materials from the Website without the prior written consent of Init Technology.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>3. Privacy Policy</p>
                            <p>&nbsp; 3.1. Your use of the Website is subject to Init Technology's Privacy Policy, which is incorporated
                                into these Terms by reference. Please review the Privacy Policy carefully to understand how Init Technology
                                collects, uses, and protects your personal information.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>4. Limitation of Liability</p>
                            <p>&nbsp;  4.1. Init Technology shall not be liable for any indirect, incidental, special, consequential, or
                                punitive damages, including but not limited to lost profits, arising from or in connection with your use of
                                the Website or any services offered through the Website.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>5. Governing Law</p>
                            <p>&nbsp; 5.1. These Terms shall be governed by and construed in accordance with the laws of
                                [Your Jurisdiction], without regard to its conflict of law principles.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>6. Changes to Terms</p>
                            <p>&nbsp; 6.1. Init Technology reserves the right to modify or revise these Terms at any time. Any changes to
                                these Terms will be effective immediately upon posting on the Website. Your continued use of the Website
                                after any such changes constitutes your acceptance of the revised Terms.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>7. Contact Information</p>
                            <p>&nbsp; 7.1. If you have any questions or concerns about these Terms, please contact us at&nbsp;
                                <a href='mailto:contact@inittechnology.co' className='underline hover:text-[#008080]'>contact@inittechnology.co</a> 
                                <span className='font-light'> or </span><a href='tel:+919664570369' className='underline hover:text-[#008080]'>+91-96645 70369</a>
                            </p>
                        </div>
                    </div>
                </div>
                <FooterIT />
            </div>
        </m.div>
    );
};

export default TermsConditions;