import React, { useEffect, useState } from 'react';
import './Header.css';
import { InlineWidget } from "react-calendly";
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";


const Header = () => {
    const location = useLocation();

    const [isChanged, setIsChanged] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleToggleChange = () => {
        setIsChanged(prevState => !prevState);
        setMenuOpen(!menuOpen);
    };

    const [IsFormOpenFor_bookCall, setIsFormOpenFor_bookCall] = useState(false);

    const openForm1 = () => {
        setIsFormOpenFor_bookCall(true);
    };

    const closeForm1 = () => {
        setIsFormOpenFor_bookCall(false);
    };

    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const savedMode = localStorage.getItem('darkMode');
        if (savedMode === 'true') {
            setIsDarkMode(true);
            document.documentElement.classList.add('dark');
        } else {
            setIsDarkMode(false);
            document.documentElement.classList.remove('dark');
        }
    }, []);

    const handleDarkModeToggle = () => {
        const newMode = !isDarkMode;
        setIsDarkMode(newMode);
        if (newMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
        localStorage.setItem('darkMode', newMode);
    };

    const navigate = useNavigate();

    const handleMobile_ourServices = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('ourServices').scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('ourServices').scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }
    };

    const handleMobile_HowitWorks = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('HowitWorks').scrollIntoView({ behavior: 'smooth' });
            }, 1500);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('HowitWorks').scrollIntoView({ behavior: 'smooth' });
            }, 1500);
        }
    };

    const handleMobile_OurProducts = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('OurProducts').scrollIntoView({ behavior: 'smooth' });
            }, 2000);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('OurProducts').scrollIntoView({ behavior: 'smooth' });
            }, 2000);
        }
    };

    const handleMobile_AboutUs = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('AboutUs').scrollIntoView({ behavior: 'smooth' });
            }, 2200);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('AboutUs').scrollIntoView({ behavior: 'smooth' });
            }, 2200);
        }
    };


    const handleDesktop_OurServices = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('OurServices').scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('OurServices').scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }
    };

    const handleDesktop_HowItWorks = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('HowItWorks').scrollIntoView({ behavior: 'smooth' });
            }, 1500);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('HowItWorks').scrollIntoView({ behavior: 'smooth' });
            }, 1500);
        }
    };

    const handleDesktop_OurProducts = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('OurProducts').scrollIntoView({ behavior: 'smooth' });
            }, 2000);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('OurProducts').scrollIntoView({ behavior: 'smooth' });
            }, 2000);
        }
    };

    const handleDesktop_AboutUs = (e) => {
        e.preventDefault();

        if (window.location.pathname === '/' || window.location.pathname === '/home') {
            setTimeout(() => {
                document.getElementById('AboutUs').scrollIntoView({ behavior: 'smooth' });
            }, 2200);
        } else {
            navigate('/', { replace: true });
            setTimeout(() => {
                document.getElementById('AboutUs').scrollIntoView({ behavior: 'smooth' });
            }, 2200);
        }
    };

    return (
        <div>
            {(location.pathname === '/' || location.pathname === '/Home') ? (

                <div className='bg-[#fafafa] dark:bg-[#020202] bg-opacity-80 dark:bg-opacity-90 flex flex-row h-auto w-[100vw] py-[1%] px-[2%] items-center fixed top-0 z-[20] md:h-20 2xl:h-28'>
                    <div className=''>
                        <a href='/'>
                            <img
                                className='h-14 w-32 md:w-44 md:h-[75px] 2xl:h-24 2xl:w-56'
                                src={isDarkMode ? "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e101c2ed-eab8-4dbc-e8af-ffd11d793700/public" : "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4025701d-7d16-446c-72cb-f012941d4b00/public"} // {/* init_Init_whiteLogo.webp */}, {/* init_Init_logo.webp */}
                                alt="Init-logo" />
                        </a>
                    </div>

                    <div className='hidden lg:flex items-center ml-auto gap-6 2xl:gap-9'>
                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="#OurServices">Our&nbsp;services</a>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="#HowItWorks">How&nbsp;it&nbsp;Works</a>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="#OurProducts">Our&nbsp;Products</a>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="#AboutUs">About&nbsp;Us</a>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="#Blogs">Blogs</a>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="#ContactUs">Contact&nbsp;Us</a>
                        </div>

                        <div className='flex'>
                            <button className="bookCall_btn" onClick={openForm1}>
                                Estimate&nbsp;Project
                                <svg viewBox="0 0 24 24" className="icon">
                                    <IoIosArrowForward />
                                </svg>
                            </button>
                        </div>

                    </div>

                    <div className='lg:hidden items-center flex ml-auto z-10'>

                        <div className='lg:hidden mr-2'>
                            <label className="toggle_lightDarkMode" htmlFor="switch">
                                <input id="switch" className="input_lightDarkMode" type="checkbox" checked={isDarkMode} onChange={handleDarkModeToggle} />
                                <div className="icon_lightDarkMode icon--moon_lightDarkMode">
                                    <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path clipRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" fillRule="evenodd"></path>
                                    </svg>
                                </div>

                                <div className="icon_lightDarkMode icon--sun_lightDarkMode">
                                    <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"></path>
                                    </svg>
                                </div>
                            </label>
                        </div>

                        <div className={isChanged ? "change" : ""} onClick={handleToggleChange}>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                        </div>
                    </div>

                    <div className='hidden lg:block'>
                        <label className="toggle_lightDarkMode" htmlFor="switch">
                            <input id="switch" className="input_lightDarkMode" type="checkbox" checked={isDarkMode} onChange={handleDarkModeToggle} />
                            <div className="icon_lightDarkMode icon--moon_lightDarkMode">
                                <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path clipRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" fillRule="evenodd"></path>
                                </svg>
                            </div>

                            <div className="icon_lightDarkMode icon--sun_lightDarkMode">
                                <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"></path>
                                </svg>
                            </div>
                        </label>
                    </div>

                    <div className='menu_bg lg:hidden fixed h-screen w-[55%] md:w-[35%]' style={{ right: menuOpen ? 0 : '-100vw', top: 0, transition: 'right 0.5s ease-in-out' }}>
                        <div className='text-lg md:text-2xl flex flex-col text-right mt-20 mr-[20%]'>
                            <a href="#ourServices" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Our Services</a><br />
                            <a href="#HowitWorks" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>How it Works</a><br />
                            <a href="#OurProducts" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Our Products</a><br />
                            <a href="#AboutUs" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>About Us</a><br />
                            <a href="#Blogs" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Blogs</a><br />
                            <a href="#ContactUs" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Contact Us</a><br />
                            <button className='bookCall_btn w-[80%] ml-[20%] bg-[#C2F8ED] p-2 rounded-md text-teal-600 mt-4 text-lg md:text-2xl' onClick={openForm1} >Book a Call</button>
                            <br /><br />
                        </div>
                    </div>

                    {IsFormOpenFor_bookCall && (
                        <>
                            <div className="block fixed h-[400vw] w-[110vw] ml-[-5%] bg-black opacity-60" onClick={closeForm1} ></div>
                            <div className="fixed block h-[650px] w-[90vw] p-4 lg:p-0 lg:w-[73vw] 2xl:w-[40vw] mx-2 mt-[710px] lg:mx-48 lg:mt-[670px] 2xl:mx-[30vw] 2xl:mt-[1000px] bg-white border border-gray-300 rounded-lg">
                                <span className="absolute right-1 lg:right-2 top-0 cursor-pointer text-2xl" onClick={closeForm1}>&times;</span>
                                <form>
                                    <div>
                                        <p className='absolute lg:m-3 uppercase'>Schedule a meeting to estimate project</p>
                                        <InlineWidget
                                            url="https://calendly.com/contact-inittechnology/30min"
                                            rootElement={document.getElementById("root")}
                                            text="Book a Call"
                                            textColor="#ffffff"
                                            color="rgb(31, 151, 151)"
                                        />
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </div>

            ) : (

                <div className='bg-[#fafafa] dark:bg-[#020202] bg-opacity-80 dark:bg-opacity-90 flex flex-row h-auto w-[100vw] py-[1%] px-[2%] items-center fixed top-0 z-[5] md:h-20 2xl:h-28'>
                    <div className=''>
                        <a href='/'>
                            <img
                                className='h-14 w-32 md:w-44 md:h-[75px] 2xl:h-24 2xl:w-56'
                                src={isDarkMode ? "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e101c2ed-eab8-4dbc-e8af-ffd11d793700/public" : "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4025701d-7d16-446c-72cb-f012941d4b00/public"} //{/* init_Init_whiteLogo.webp */}, {/* init_Init_logo.webp */}
                                alt="Init-logo" />
                        </a>
                    </div>

                    <div className='hidden lg:flex items-center ml-auto gap-6 2xl:gap-9'>
                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <button onClick={(e) => { handleToggleChange(); handleDesktop_OurServices(e); }}>Our&nbsp;services</button>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <button onClick={(e) => { handleToggleChange(); handleDesktop_HowItWorks(e); }}>How&nbsp;it&nbsp;Works</button>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <button onClick={(e) => { handleToggleChange(); handleDesktop_OurProducts(e); }}>Our&nbsp;Products</button>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <button onClick={(e) => { handleToggleChange(); handleDesktop_AboutUs(e); }}>About&nbsp;Us</button>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="/Blogs">Blogs</a>
                        </div>

                        <div className='hover:text-teal-600 text-md 2xl:text-2xl'>
                            <a href="#ContactUs">Contact&nbsp;Us</a>
                        </div>

                        <div className='ml-3 flex'>
                            <button className="bookCall_btn" onClick={openForm1}>
                                Estimate&nbsp;Project
                                <svg viewBox="0 0 24 24" className="icon">
                                    <IoIosArrowForward />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className='lg:hidden items-center flex ml-auto z-10'>

                        <div className='lg:hidden mr-2'>
                            <label className="toggle_lightDarkMode" htmlFor="switch">
                                <input id="switch" className="input_lightDarkMode" type="checkbox" checked={isDarkMode} onChange={handleDarkModeToggle} />
                                <div className="icon_lightDarkMode icon--moon_lightDarkMode">
                                    <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path clipRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" fillRule="evenodd"></path>
                                    </svg>
                                </div>

                                <div className="icon_lightDarkMode icon--sun_lightDarkMode">
                                    <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"></path>
                                    </svg>
                                </div>
                            </label>
                        </div>

                        <div className={isChanged ? "change" : ""} onClick={handleToggleChange}>
                            <div className="bar1"></div>
                            <div className="bar2"></div>
                            <div className="bar3"></div>
                        </div>
                    </div>

                    <div className='hidden lg:block'>
                        <label className="toggle_lightDarkMode" htmlFor="switch">
                            <input id="switch" className="input_lightDarkMode" type="checkbox" checked={isDarkMode} onChange={handleDarkModeToggle} />
                            <div className="icon_lightDarkMode icon--moon_lightDarkMode">
                                <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path clipRule="evenodd" d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z" fillRule="evenodd"></path>
                                </svg>
                            </div>

                            <div className="icon_lightDarkMode icon--sun_lightDarkMode">
                                <svg height="25" width="25" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z"></path>
                                </svg>
                            </div>
                        </label>
                    </div>

                    <div className='menu_bg lg:hidden fixed h-screen w-[55%] md:w-[35%]' style={{ right: menuOpen ? 0 : '-100vw', top: 0, transition: 'right 0.5s ease-in-out' }}>
                        <div className='text-lg md:text-2xl flex flex-col text-right mt-20 mr-[20%]'>
                            <button
                                className='text-white text-lg md:text-2xl text-right'
                                onClick={(e) => {
                                    handleToggleChange();
                                    handleMobile_ourServices(e);
                                }}
                            >Our Services</button><br />
                            <button
                                className='text-white text-lg md:text-2xl text-right'
                                onClick={(e) => {
                                    handleToggleChange();
                                    handleMobile_HowitWorks(e);
                                }}
                            >How it Works</button><br />
                            <button
                                className='text-white text-lg md:text-2xl text-right'
                                onClick={(e) => {
                                    handleToggleChange();
                                    handleMobile_OurProducts(e);
                                }}
                            >Our Products</button><br />
                            <button
                                className='text-white text-lg md:text-2xl text-right'
                                onClick={(e) => {
                                    handleToggleChange();
                                    handleMobile_AboutUs(e);
                                }}
                            >About Us</button><br />
                            <a href="/Blogs" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Blogs</a><br />
                            <a href="#ContactUs" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Contact Us</a><br />
                            <button className='bookCall_btn w-[80%] ml-[20%] bg-[#C2F8ED] p-2 rounded-md text-teal-600 mt-4 text-lg md:text-2xl' onClick={openForm1} >Book a Call</button>
                            <br /><br />
                        </div>
                    </div>

                    {IsFormOpenFor_bookCall && (
                        <>
                            <div className="block fixed h-[400vw] w-[110vw] ml-[-5%] bg-black opacity-60" onClick={closeForm1} ></div>
                            <div className="fixed block h-[650px] w-[90vw] p-4 lg:p-0 lg:w-[73vw] 2xl:w-[40vw] mx-2 mt-[710px] lg:mx-48 lg:mt-[680px] 2xl:mx-[30vw] 2xl:mt-[1000px] bg-white border border-gray-300 rounded-lg">
                                <span className="absolute right-1 lg:right-2 top-0 cursor-pointer text-2xl" onClick={closeForm1}>&times;</span>
                                <form>
                                    <div>
                                        <p className='absolute lg:m-3 uppercase'>Schedule a meeting to estimate project</p>
                                        <InlineWidget
                                            url="https://calendly.com/contact-inittechnology/30min"
                                            rootElement={document.getElementById("root")}
                                            text="Book a Call"
                                            textColor="#ffffff"
                                            color="rgb(31, 151, 151)"
                                        />
                                    </div>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default Header;