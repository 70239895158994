import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const schema = {
  "@context": "https://schema.org",
  "@type": "ITService",
  "name": "Init Technology",
  "url": "https://inittechnology.co",
  "logo": "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4025701d-7d16-446c-72cb-f012941d4b00/public",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+91-96645-70369",
    "contactType": "Customer Service",
    "areaServed": "IN",
    "availableLanguage": ["English", "Hindi"]
  },
  "sameAs": [
    "https://www.facebook.com/people/Init-Technology/61554743413903/?mibextid=9R9pXO",
    "https://www.linkedin.com/company/inittechnologyco",
    "https://x.com/_inittechnology",
    "https://www.instagram.com/init.technology"
  ],
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Race Course Road",
    "addressLocality": "Rajkot",
    "addressRegion": "Gujarat",
    "postalCode": "360001",
    "addressCountry": "IN"
  },
  "description": "Init Technology is a Rajkot-based IT company specializing in software development, SaaS solutions, and technology consulting.",
  "foundingDate": "2023",
  "founders": [
    {
      "@type": "Person",
      "name": "Ms. Naju Keshwala"
    },
    {
      "@type": "Person",
      "name": "Mr. Sikandar Bathvar"
    }
  ],
  "offers": {
    "@type": "OfferCatalog",
    "name": "Software Solutions",
    "itemListElement": [
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Custom Software Development"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Product Development"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Technology Consulting"
        }
      },
      {
        "@type": "Offer",
        "itemOffered": {
          "@type": "Service",
          "name": "Website Designing and Development"
        }
      }
    ]
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
