import React, { useEffect, useRef, useState } from 'react';
import './Home.css';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
// import SplashScreen from '../SplashScreen_IT/SplashScreenIT';
import { useNavigate } from 'react-router-dom';

import { ImQuotesLeft } from "react-icons/im";
import { ImQuotesRight } from "react-icons/im";
import { SlArrowRight } from "react-icons/sl";
// import { GoArrowRight } from "react-icons/go";
import { SiTicktick } from "react-icons/si";
import { IoIosArrowForward } from "react-icons/io";
import { MdOutlineCircle } from "react-icons/md";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Lottie from 'lottie-react';
// import animation1 from '../../../animations/animation1.json';
import animation2 from '../../../animations/animation2.json';
// import animation3 from '../../../animations/animation4.json';
// import animation4 from '../../../animations/animation7.json';
import coding from '../../../animations/Coding.json';
import debug from '../../../animations/Debug.json';
import ideation from '../../../animations/Ideation.json';
import meeting from '../../../animations/Meeting.json';
import scalling from '../../../animations/Scalling.json';
// import ourProducts_bg from '../../../animations/ourProducts_background.json';
import OurProduct_bg from '../../../animations/OurProduct_bg.json';

import { motion as m } from 'framer-motion';

const milestones = [1, 2, 3, 4, 5];

const milestoneLabels = {
    1: 'Meeting',
    2: 'Ideation',
    3: 'Coding',
    4: 'Debugging',
    5: 'Scaling'
};

const animations = ['meeting', 'ideation', 'coding', 'debug', 'scalling'];

const Home = () => {

    const navigate = useNavigate();

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const responsiveBlogs = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 900 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 900, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1.5
        }
    };

    const [lineHeight, setLineHeight] = useState(0);
    const [CircleHeight, setCircleHeight] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            let maxLineHeight;

            if (screenWidth <= 1024) {
                maxLineHeight = window.innerHeight * 0.85;
            } else if (screenWidth <= 1440) {
                maxLineHeight = window.innerHeight * 0.80;
            } else if (screenWidth <= 1920) {
                maxLineHeight = window.innerHeight * 0.75;
            } else {
                maxLineHeight = window.innerHeight * 0.7;
            }

            const maxCircleHeight = 14;
            const scrolledHeight = window.scrollY;

            const newLineHeight = Math.min(scrolledHeight, maxLineHeight);
            const newCircleHeight = Math.min(scrolledHeight, maxCircleHeight);

            setLineHeight(newLineHeight);
            setCircleHeight(newCircleHeight);
        };

        const handleScroll = () => handleResize();
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [showRow1, setShowRow1] = useState(true);
    const [showRow2, setShowRow2] = useState(false);

    const [activeButton, setActiveButton] = useState('btn10');

    const handleClick_btn10 = () => {
        setShowRow1(true);
        setShowRow2(false);
        setActiveButton('btn10');
    };

    const handleClick_btn11 = () => {
        setShowRow2(true);
        setShowRow1(false);
        setActiveButton('btn11');
    };

    // const [selectedAnimation, setSelectedAnimation] = useState('meeting');

    // const [fade, setFade] = useState(false);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setFade(true);
    //         setTimeout(() => {
    //             setSelectedAnimation(prev => {
    //                 const currentIndex = animations.indexOf(prev);
    //                 const nextIndex = (currentIndex + 1) % animations.length;
    //                 return animations[nextIndex];
    //             });
    //             setFade(false);
    //         }, 500); //  fade effect
    //     }, 5000); // every 5 seconds

    //     return () => clearInterval(interval);
    // }, []);

    const services = [
        {
            title: "Website Designing & Development",
            description: "No more looking around- Init Technology brings you an affordable destination for Modern and Chic Web Development Solutions.",
            link: "/OurServices_IT/WebsiteDevelopment",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b053b87b-508e-41fa-314e-1c1c4f46c800/public", //init_openStudios_box.webp
            img2: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b3ed5eef-b783-4d50-11e2-88fbaa3d0b00/public", //init_soham_box.webp
            img3: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/37adfae1-d8ba-49c1-9878-b22f21a14500/public", //init_rpj_box.webp
            id: 1
        },
        {
            title: "E-Commerce Development",
            description: "From user-friendly interfaces to payment integration and mobile responsiveness, we'll ensure that your e-commerce website is optimised for success.",
            link: "/OurServices_IT/Ecommerce",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a3a441bd-ae11-4eb0-1c85-b56e30cd1c00/public", //init_dresswala_box.webp
            img2: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b346539b-4674-41c1-61f0-913677b46a00/public", //init_houseOfShah_box.webp
            img3: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b3ed5eef-b783-4d50-11e2-88fbaa3d0b00/public", //init_soham_box.webp
            id: 2
        },
        {
            title: "Software Development",
            description: "Whether you need a custom web application, mobile app, or enterprise software solution, we have the expertise to bring your vision to life.",
            link: "/OurServices_IT/SoftwareDevelopment",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/160bb1ed-8b8c-4c4e-4c65-33e62498ad00/public", //init_neel_box.webp
            img2: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6bb67073-8017-4783-0cdb-5869a5d18900/public", //init_waverise_box.webp
            img3: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/3ff148e7-1f3e-47f3-49ea-5af8a7267f00/public", //init_quotsync_box.webp
            id: 3
        },
        {
            title: "Mobile App Development",
            description: "We leverage the latest technologies and best practices to ensure that your app is not only functional but also scalable, secure, and user-friendly.",
            link: "/OurServices_IT/MobileAppDevelopment",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6bb67073-8017-4783-0cdb-5869a5d18900/public", //init_waverise_box.webp
            id: 4
        },
        {
            title: "Written Production",
            description: "We understand the power of words, from writing captivating content for your website, engaging articles for your blog, or polished copy for your marketing materials, our team of experienced writers.",
            link: "/OurServices_IT/WrittenProduction",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b053b87b-508e-41fa-314e-1c1c4f46c800/public", //init_openStudios_box.webp
            img2: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a3a441bd-ae11-4eb0-1c85-b56e30cd1c00/public", //init_dresswala_box.webp
            img3: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/37adfae1-d8ba-49c1-9878-b22f21a14500/public", //init_rpj_box.webp
            id: 5
        },
        {
            title: "Management Services",
            description: "Explore our management services below to learn how we can support your organisation's growth and development.",
            link: "/OurServices_IT/ManagementServices",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/160bb1ed-8b8c-4c4e-4c65-33e62498ad00/public", //init_neel_box.webp
            img2: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b3ed5eef-b783-4d50-11e2-88fbaa3d0b00/public", //init_soham_box.webp
            img3: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a3a441bd-ae11-4eb0-1c85-b56e30cd1c00/public", //init_dresswala_box.webp
            id: 6
        },
        {
            title: "WordPress",
            description: "Work with our experienced designers to create a unique and visually stunning website that reflects your brand identity.",
            link: "/OurServices_IT/Wordpress",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b346539b-4674-41c1-61f0-913677b46a00/public", //init_houseOfShah_box.webp
            id: 7
        },
        {
            title: "SEO",
            description: "With millions of websites competing for attention online, it's crucial to ensure that your website ranks well in search engine results to attract organic traffic and reach your target audience effectively.",
            link: "/OurServices_IT/Seo",
            img1: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b053b87b-508e-41fa-314e-1c1c4f46c800/public", //init_openStudios_box.webp
            img2: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a3a441bd-ae11-4eb0-1c85-b56e30cd1c00/public", //init_dresswala_box.webp
            img3: "https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/37adfae1-d8ba-49c1-9878-b22f21a14500/public", //init_rpj_box.webp
            id: 8
        }
    ];

    const [visibleContainer, setVisibleContainer] = useState(null);

    const handleNavigation = (link, title) => {
        navigate(link, { state: { title } });
    };

    const toggleContainer = (id) => {
        setVisibleContainer(visibleContainer === id ? null : id);
    };

    const [selectedService, setSelectedService] = useState(null);
    const [isFadingIn, setIsFadingIn] = useState(false);

    const handleClick = (service) => {
        if (selectedService !== service) {
            setIsFadingIn(false); 
            setTimeout(() => {
                setSelectedService(service); 
                setIsFadingIn(true); 
            }, 500); 
        }
    };

    const [currentStep, setCurrentStep] = useState(1);
    const [selectedAnimation, setSelectedAnimation] = useState(animations[0]);
    const [fade, setFade] = useState(false);
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleOnClick = (milestone) => {
        setCurrentStep(milestone);
        setSelectedAnimation(animations[milestone - 1]);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isVisible) {
                setFade(true);
                setTimeout(() => {
                    setSelectedAnimation(prev => {
                        const currentIndex = animations.indexOf(prev);
                        const nextIndex = (currentIndex + 1) % animations.length;
                        setCurrentStep(nextIndex + 1);
                        return animations[nextIndex];
                    });
                    setFade(false);
                }, 400); // fade effect
            }
        }, 3000); // every 3 seconds

        return () => clearInterval(interval);
    }, [isVisible]);

    useEffect(() => {
        const currentSection = sectionRef.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        if (currentSection) {
            observer.observe(currentSection);
        }

        return () => {
            if (currentSection) {
                observer.unobserve(currentSection);
            }
        };
    }, []);

    const blogPosts = [
        {
            href: '/Blog3',
            imgSrc: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/8a887af9-22ef-4e62-b3ee-94cac66a0800/public', //init_blog3_cover.webp
            title: 'Tech Trends 2024: How SaaS is changing the Software Industry',
            author: 'Naju.K',
            date: 'Sep 14, 2024',
            readTime: '4 min read'
        },
        {
            href: '/Blog2',
            imgSrc: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/aabe2924-278c-43de-80e9-b99fd8cbf900/public', //init_blog2_cover.webp
            title: 'Tech Trends 2024: Why React is the Best Choice for Modern Web Apps',
            author: 'Naju.K',
            date: 'Sep 10, 2024',
            readTime: '6 min read'
        },
        {
            href: '/Blog1',
            imgSrc: 'https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/6634e202-d55c-4e46-4634-08ea1e060400/public', //init_tech_trends.webp
            title: 'Tech Trends 2024: What’s Hot in IT',
            author: 'Naju.K',
            date: 'Sep 1, 2024',
            readTime: '3 min read'
        }
    ];

    const partners = [
        {
            name: 'Mr. Sikandar Bathvar',
            link: 'https://www.linkedin.com/in/sikandar-bathvar-5a2b46154/',
            title: 'Director',
        },
        {
            name: 'Ms. Naju Keshwala',
            link: 'https://www.linkedin.com/in/naju-keshwala-37075912a/',
            title: 'Director',
        },
    ];

    const testimonials = [
        {
            feedback: "We collaborated with INIT Technology to build the RPJ Hotel website, and the experience was excellent. The team was professional, attentive, and understood our vision, even suggesting creative enhancements. The end result is a visually appealing and user-friendly website that received great feedback. The project was delivered on time, and they were responsive to our needs throughout. Highly recommend INIT Technology.",
            author: "-Mr. Ajayrajsinh Jadeja",
            companyName: 'RPJ Hotels',
            websiteLink: 'https://rpjhotels.com/',
        },
        {
            feedback: "Working with Init Technology to create the Soham Villas website was a fantastic experience. The site is visually stunning, easy to navigate, and integrates booking features, making it simple for clients to plan their stays. We’re beyond pleased with the results.",
            author: "-Mr. Parth Thaker",
            companyName: 'Soham Villas',
            websiteLink: 'https://sohamvillas.com/',
        },
        {
            feedback: "INIT developed our software exactly as proposed, delivering it on time with excellent support. The team was highly responsive, receptive to feedback, and proactive in resolving any challenges. The final product fully met our expectations. ",
            author: "-Mr. Neel Kamdar",
            companyName: 'Neel Ayurvedics',
            websiteLink: 'https://dash.shipneel.in/',
        },
        {
            feedback: "InitTechnology delivered exceptional IT services, from web development to design and digital marketing. Their team built a responsive, SEO-optimized website that boosted our online presence. The design was sleek and on-brand, and their marketing strategies significantly increased our reach. Professional, efficient, and results-driven – highly recommend!",
            author: "-Mr. Nimit Kansagra",
            companyName: 'HostingSpell',
            websiteLink: 'https://hostingspell.com/',
        },
    ];

    const quotsync_Features = [
        "AI<br /> INTEGRATED",
        "AUTOMATE QUOTATIONS ON THE GO",
        "EASE OF MANAGEMENT & MORE",
        "GENERATE ACCURATE QUOTES INSTANTLY"
    ];
    
    const waveriseHub_Features = [
        "TASK, PERFORMANCE MANAGEMENT & MORE",
        "TRACK<br /> LOCATION",
        "REAL-TIME UPDATES AND TRACKING",
        "GENERATE ACCURATE QUOTES INSTANTLY"
    ];

    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] md:text-xl lg:text-[16px] 2xl:text-2xl leading-7 md:leading-8 lg:leading-7 2xl:leading-10'>
                {/* <SplashScreen /> */}
                <HeaderIT />

                {/* Hero section starts from here */}
                <div className=''>
                    <div className='absolute flex -mt-[16%] ml-[66%] md:-mt-[6%] lg:ml-[80%]'>
                        <Lottie
                            animationData={animation2}
                            loop={true}
                            autoplay={true}
                            className='h-[32vw] w-[32vw] lg:h-[20vw] lg:w-[20vw]'
                            rendererSettings={{
                                preserveAspectRatio: 'xMidYMid slice'
                            }}
                        />
                    </div>

                    <div className='mt-16 md:mt-20 flex flex-col items-center'>
                        <div className='text-4xl md:text-5xl lg:text-6xl 2xl:text-8xl font-bold mt-[12%]'>
                            <span className='text-[#008080]'>INIT&nbsp;</span>for the long run
                        </div>

                        <div className='flex flex-col text-center items-center text-sm md:text-lg lg:text-[16px] 2xl:text-2xl mt-[8%]'>
                            See what happens when technology<br className='lg:hidden' /> meets your Vision
                            <a href='#ourServices' className="discoverMore_btn">
                                Discover more
                            </a>
                        </div>

                        <div className='text-center text-[#B2BEB5] text-[10px] md:text-[14px] 2xl:text-xl leading-[2] mt-[8%] lg:mt-[4%] w-[90vw] lg:w-[75vw]' style={{ wordSpacing: '12px', letterSpacing: '0.5px' }}>
                            <span id='ourServices'></span>
                            <span id='OurServices'></span>
                            HTML CSS PHP JAVA DATABASE MySQL PostgreSQL FIGMA SHOPIFY Node.JS WORDPRESS Angular ReactJS React-Native FRAMER TypeScript Spring-Boot
                        </div>

                        <div className='absolute -ml-[88%] lg:-ml-[85%] mt-[12%] lg:mt-[0%] opacity-50'>
                            <Lottie
                                animationData={animation2}
                                loop={true}
                                autoplay={true}
                                className='h-[70vw] w-[70vw] lg:h-[40vw] lg:w-[40vw]'
                                rendererSettings={{
                                    preserveAspectRatio: 'xMidYMid slice'
                                }}
                            />
                        </div>
                    </div>
                </div>
                {/* Hero section ends here */}

                {/* Our services starts from here */}
                <div>
                    {/* Mobile view starts from here */}
                    <div className='lg:hidden mt-[8%] mx-[3%]'>
                        <div>
                            <p className='text-2xl md:text-3xl font-bold'>Our Services</p>
                            <div className='w-10 h-[2px] bg-teal-600 rounded-md'></div>
                        </div>

                        <p className='mt-5'>
                            Welcome to Init Technology.<br />
                            As a top-rated business IT consulting company, we specialise in curating innovativeservices for your needs.
                            Whether you're a startup or an established enterprise seeking operational optimisation, our dedicated team is
                            here to support you at every stage.
                        </p>

                        <div className='mt-3'>
                            {services.map(service => (
                                <div key={service.id}>
                                    <button
                                        className='flex font-bold mt-4 md:mt-6'
                                        onClick={() => toggleContainer(service.id)}
                                    >
                                        {service.title}
                                        <img
                                            className={`h-4 mt-0.5 ml-3 md:mt-1 md:ml-5 transition-transform duration-300 ${visibleContainer === service.id ? 'rotate-90' : ''}`} src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/09ee0c5b-2d3f-44f2-2730-630ed8103f00/public" //init_teal_right_arrow.webp
                                            alt="arrow-icon"
                                        />
                                    </button>
                                    {visibleContainer === service.id && (
                                        <div className='my-2'>
                                            <div>{service.description}</div>
                                            <div className='flex mt-3'>

                                                <a href={service.link}>
                                                    <img className="h-28 w-28 mx-1.5 shadow-custom rounded" src={service.img1} alt="Img"></img>
                                                </a>

                                                {service.img2 && (
                                                    <a href={service.link}>
                                                        <img className="h-28 w-28 mx-1.5 shadow-custom rounded" src={service.img2} alt="Img"></img>
                                                    </a>
                                                )}

                                                {service.img3 && (
                                                    <a href={service.link}>
                                                        <img className="h-28 w-28 mx-1.5 shadow-custom rounded" src={service.img3} alt="Img"></img>
                                                    </a>
                                                )}
                                            </div>
                                            <button
                                                className='mt-3 group'
                                                onClick={() => handleNavigation(service.link, service.title)}
                                            >
                                                View more
                                                <div className='h-[2px] w-16 -ml-3 bg-black dark:bg-white transition-all duration-500 ease-in-out group-hover:ml-2.5 group-hover:bg-[#008080]'></div>
                                            </button>
                                        </div>
                                    )}
                                    <span id='HowitWorks'></span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* Mobile view ends here */}

                    {/* Desktop view starts from here */}
                    <div className='hidden lg:block mt-[3%] 2xl:mt-[5%] lg:mx-[12%] h-[45em] overflow-hidden'>
                        <div className='flex'>
                            <div className='w-[45%]'>
                                <div>
                                    <p className='text-3xl font-bold 2xl:text-5xl'>Our Services</p>
                                    <div className='w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1'></div>
                                </div>

                                <p className='mt-[5%]'>
                                    Welcome to Init Technology.<br />
                                    As a top-rated business IT consulting company, we specialise in curating innovative services for your needs.
                                    Whether you're a startup or an established enterprise seeking operational optimisation, our dedicated team is
                                    here to support you at every stage.
                                </p>

                                <div className='mt-[2%] flex flex-col'>
                                    {services.map((service, index) => (
                                        <button
                                            key={index}
                                            className='my-[2%]'
                                            onMouseEnter={() => handleClick(service)}
                                        >
                                            <p className='group font-medium flex items-center relative group-hover:underline'>
                                                {service.title}
                                                <IoIosArrowForward className="text-[#008080] h-5 w-5 ml-5 2xl:h-6 2xl:w-6 2xl:mt-0.5 hidden group-hover:inline-block" />
                                            </p>
                                        </button>
                                    ))}
                                </div>

                            </div>

                            <div className='w-[10%] px-[5%]'>
                                <div
                                    style={{
                                        height: `${lineHeight}px`,
                                        width: '2px',
                                        backgroundColor: 'teal',
                                        top: '49.5vw',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        transition: 'height 0.0s ease',
                                    }}
                                ></div>

                                <div
                                    style={{
                                        width: '14px',
                                        height: `${CircleHeight}px`,
                                        borderRadius: '50%',
                                        backgroundColor: 'teal',
                                        top: `calc(49.5vw + ${lineHeight}px)`,
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        transition: 'height 1.8s ease',
                                    }}
                                ></div>
                            </div>

                            <div className=' w-[45%]'>
                                {selectedService && (
                                    <div className={`flex flex-col text-center items-center transition-opacity duration-700 ease-in-out ${
                                        isFadingIn ? "opacity-100" : "opacity-0"
                                    }`}>
                                        <div className='text-3xl font-medium 2xl:text-5xl'>
                                            {selectedService.title}
                                        </div>
                                        <p className='mt-[3%] text-left'>{selectedService.description}</p>

                                        <div className='flex flex-col mt-2 2xl:mt-6'>

                                            <a href={selectedService.link}>
                                                <img className="h-[9vw] w-auto my-[0.5vw] shadow-custom rounded" src={selectedService.img1} alt="Img" />
                                            </a>

                                            {selectedService.img2 && (
                                                <a href={selectedService.link}>
                                                    <img className="h-[9vw] w-auto my-[0.5vw] shadow-custom rounded" src={selectedService.img2} alt="Img"></img>
                                                </a>
                                            )}

                                            {selectedService.img3 && (
                                                <a href={selectedService.link}>
                                                    <img className="h-[9vw] w-auto my-[0.5vw] shadow-custom rounded" src={selectedService.img3} alt="Img"></img>
                                                </a>
                                            )}
                                        </div>

                                        <button onClick={() => handleNavigation(selectedService.link, selectedService.title)} className='group mt-[2%] group'>
                                            View more
                                            <div className='h-[2px] w-16 -ml-3 bg-black dark:bg-white transition-all duration-500 ease-in-out group-hover:ml-1.5 group-hover:bg-[#008080]'></div>
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <span id='HowItWorks'></span>
                    </div>
                    {/* Desktop view ends here */}
                </div>
                {/* Our services ends here */}

                {/* How it works starts from here */}<br />
                <div className='mt-[0%] mx-[2%] lg:mx-[8%]' ref={sectionRef}>
                    <div className='border border-black dark:border-white rounded-xl py-[1%] flex flex-col items-center'>
                        <div className='w-full flex flex-col items-center'>
                            <div className={`flex items-center transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}>
                                {selectedAnimation === 'meeting' && (
                                    <Lottie
                                        animationData={meeting} loop={true} autoplay={true}
                                        className='h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]'
                                        rendererSettings={{
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }}
                                    />
                                )}
                                {selectedAnimation === 'ideation' && (
                                    <Lottie
                                        animationData={ideation} loop={true} autoplay={true}
                                        className='h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]'
                                        rendererSettings={{
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }}
                                    />
                                )}
                                {selectedAnimation === 'coding' && (
                                    <Lottie
                                        animationData={coding} loop={true} autoplay={true}
                                        className='h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]'
                                        rendererSettings={{
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }}
                                    />
                                )}
                                {selectedAnimation === 'debug' && (
                                    <Lottie
                                        animationData={debug} loop={true} autoplay={true}
                                        className='h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]'
                                        rendererSettings={{
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }}
                                    />
                                )}
                                {selectedAnimation === 'scalling' && (
                                    <Lottie
                                        animationData={scalling} loop={true} autoplay={true}
                                        className='h-[60vw] w-[60vw] lg:h-[28vw] lg:w-[28vw]'
                                        rendererSettings={{
                                            preserveAspectRatio: 'xMidYMid slice'
                                        }}
                                    />
                                )}
                            </div>

                            <div className="progress-container">
                                <div className="progress-bar">
                                    {milestones.map((milestone, index) => (
                                        <div
                                            key={index}
                                            className={`milestone ${currentStep >= milestone ? 'completed' : ''}`}
                                            onClick={() => handleOnClick(milestone)}
                                        >
                                            <span
                                                className={`milestone-icon ${currentStep === milestone ? 'current' : ''}`}
                                            >
                                                {currentStep >= milestone ? <SiTicktick /> : <MdOutlineCircle />}
                                            </span>
                                            <div className="milestone-text text-[3vw] lg:text-[1vw]">
                                                {milestoneLabels[milestone] || ''}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <span id='OurProducts'></span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* How it works ends here */}

                {/* Our products starts from here */}
                <div className='mt-[8%] lg:mt-[5%] 2xl:mt-20 mx-[3%] lg:mx-[12%]'>
                    <div>
                        <p className='text-2xl md:text-3xl font-bold 2xl:text-5xl'>Our Products</p>
                        <div className='w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1'></div>
                    </div>

                    <div className='flex justify-center mt-5 text-lg md:text-2xl lg:text-xl 2xl:text-3xl'>
                        <button
                            className={`quotaSync_Home ${activeButton === 'btn10' ? 'active_quotaSync_Home' : ''}`}
                            onClick={handleClick_btn10}
                        >
                            &nbsp;QuotSync&nbsp;
                        </button>

                        <button
                            className={`waveriseHub_Home ${activeButton === 'btn11' ? 'active_waveriseHub_Home' : ''}`}
                            onClick={handleClick_btn11}
                        >
                            &nbsp;Waverise Hub&nbsp;
                        </button>
                    </div>

                    {showRow1 && (
                        <div className='flex flex-col items-center text-center'>
                            <p className='mt-8 lg:w-[45vw] 2xl:my-10'>QuotSync- your essential invoice management software.</p>
                            <p className="mt-3 underline underline-offset-8 font-semibold">Coming Soon</p>

                            <div className='grid grid-cols-2 md:flex gap-5 mt-5 items-center'>

                                {quotsync_Features.map((feature, index) => (
                                    <div key={index} className='relative p-1 rounded h-40 w-40 2xl:h-60 2xl:w-60 !border border-black dark:border-white'>
                                        <Lottie
                                            animationData={OurProduct_bg}
                                            loop={true}
                                            autoplay={true}
                                            className='opacity-90 md:opacity-80 absolute bottom-0 right-0 md:-bottom-4 md:-right-2 z-0 h-[12vw] w-[12vw] md:h-[5vw] md:w-[5vw]'
                                            rendererSettings={{
                                                preserveAspectRatio: 'xMidYMid slice',
                                            }}
                                        />
                                        <div className="relative z-10 flex items-center justify-center h-full">
                                            <span className="font-bold text-black dark:text-white" dangerouslySetInnerHTML={{ __html: feature }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {showRow2 && (
                        <div className='flex flex-col items-center text-center'>
                            <p className='mt-8 lg:w-[45vw] 2xl:my-10'>WaveRise Hub empowers you to track your team's performance, ensure accountability,
                                and optimise productivity—all without the need for micromanagement.</p>
                            <p className="mt-3 underline underline-offset-8 font-semibold">Coming Soon</p>

                            <div className='grid grid-cols-2 md:flex gap-5 mt-5 items-center'>

                                {waveriseHub_Features.map((feature, index) => (
                                    <div key={index} className='relative p-1 rounded h-40 w-40 2xl:h-60 2xl:w-60 !border border-black dark:border-white'>
                                        <Lottie
                                            animationData={OurProduct_bg}
                                            loop={true}
                                            autoplay={true}
                                            className='opacity-90 md:opacity-80 absolute bottom-0 right-0 md:-bottom-4 md:-right-2 z-0 h-[12vw] w-[12vw] md:h-[5vw] md:w-[5vw]'
                                            rendererSettings={{
                                                preserveAspectRatio: 'xMidYMid slice',
                                            }}
                                        />
                                        <div className="relative z-10 flex items-center justify-center h-full">
                                            <span className="font-bold text-black dark:text-white" dangerouslySetInnerHTML={{ __html: feature }} />
                                        </div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    )}
                </div>
                {/* Our products ends here */}

                {/* Testimonials starts from here */}
                <div className='bg-[#EAEAEA] dark:bg-[#505050] mx-[3%] lg:mx-[6.8%] rounded-xl py-[2%] mt-[8%] lg:mt-[5%]'>
                    <div className='mx-[6%]'>
                        <div>
                            <p className='text-2xl md:text-3xl font-bold 2xl:text-5xl'>Testimonials</p>
                            <div className='w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1'></div>
                        </div>

                        <div>
                            <Carousel
                                responsive={responsive}
                                // showDots={true}
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={9000}
                                transitionDuration={1000}
                                keyBoardControl={true}
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                arrows={true}
                            >
                                {testimonials.map((testimonial, index) => (
                                    <div key={index} className='flex-col items-center w-[80vw] md:w-[70vw] lg:w-[50vw] mx-auto mt-3 mb-8 cursor-pointer py-2 px-7'>
                                        <ImQuotesLeft />
                                        <p className='mx-6'>
                                            {testimonial.feedback}
                                            {testimonial.author && (
                                                <>
                                                    <br /> <br />
                                                    <span><b>{testimonial.author}, <a href={testimonial.websiteLink} target='_blank' rel='noreferrer'>{testimonial.companyName}</a></b></span>
                                                </>
                                            )}
                                        </p>
                                        <ImQuotesRight className='float-right' />
                                        <span id='AboutUs'></span>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
                {/* Testimonials ends here */}

                {/* About us starts from here */}
                <div className='mt-[8%] lg:mt-[5%] mx-[3%] lg:mx-[12%]'>
                    <div className='grid lg:grid-cols-2'>
                        <div className=''>
                            <p className='text-2xl md:text-3xl font-bold 2xl:text-5xl'>About Us</p>
                            <div className='w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1'></div>

                            <p className='mt-5'>The foundation of Init Technology stemmed from the idea that while there are many varieties to choose from
                                in the IT industry, only a few stand out and provide quality assurance, deliver timely, and communicate
                                consistently throughout a project and thereafter!<br /><br />

                                We are committed to building long-term partnerships with our clients, providing ongoing support and
                                guidance to help them succeed. Our team is intricately chosen based on their strengths, enabling us to
                                fulfil these responsibilities with excellence.</p>
                        </div>

                        <div className='flex flex-col mt-[8%] lg:mt-0 lg:ml-auto'>
                            <p className='text-2xl md:text-3xl lg:text-2xl font-bold 2xl:text-4xl'>Our&nbsp;Team</p>
                            <div className='w-10 h-[2px] bg-teal-600 rounded-md 2xl:w-16 2xl:h-[3px] 2xl:mt-1'></div>

                            <div className='mt-5 2xl:text-2xl'>
                                {partners.map((partner, index) => (
                                    <div key={index} className='my-3'>
                                        <p className='flex'>
                                            {partner.name}
                                            <a href={partner.link} target='_blank' rel='noreferrer'>
                                                <img className="h-5 ml-2 hover:scale-90" src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/e548aea1-298b-440e-7f6d-f5630aac4c00/public" alt="LinkedIn" /> {/* init_linkedin_icon.webp */}
                                            </a>
                                        </p>
                                        <p><i className='font-light'>{partner.title}</i></p>
                                    </div>
                                ))}

                                <span id='Blogs'></span>

                                <div className='mt-[2%] lg:ml-[10vw] group cursor-pointer'>
                                    <a href='/OurTeam' className='flex'>View&nbsp;more</a>
                                    <div className='h-[2px] w-16 -ml-3 bg-black dark:bg-white transition-all duration-500 ease-in-out group-hover:ml-2 group-hover:bg-[#008080]'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* About us ends here */}

                {/* Blogs starts from here */}
                <div className='mt-[8%] lg:mt-[4%] mx-[3%] lg:mx-[12%]'>
                    <div className='grid grid-cols-2'>
                        <div className='w-[64vw] md:w-[72vw]'>
                            <Carousel
                                responsive={responsiveBlogs}
                                infinite={true}
                                // autoPlay={true}
                                autoPlaySpeed={9000}
                                transitionDuration={1000}
                                keyBoardControl={true}
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                arrows={false}
                            >
                                {blogPosts.map((blog, index) => (
                                    <a key={index} href={blog.href}>
                                        <div className='bg-white dark:bg-[#252525] shadow-md hover:shadow-custom m-[3%] lg:mx-[6%] p-[3%] rounded-lg'>
                                            <img src={blog.imgSrc} alt="Blog-Img" />
                                            <p className='font-medium mt-2'>{blog.title}</p>
                                            <p className='text-sm 2xl:text-xl mt-6'>{blog.author}</p>
                                            <p className='text-[12px] 2xl:text-lg font-light mt-1'>{`${blog.date} ∙ ${blog.readTime}`}</p>
                                        </div>
                                    </a>
                                ))}

                            </Carousel>
                        </div>

                        <div className='flex items-center w-[12vw] md:w-[10vw] ml-[27vw] md:ml-[32vw]'>
                            <a href="/Blogs">
                                <SlArrowRight className='bg-[#008080] hover:border-teal-600 hover:border-2 hover:bg-transparent text-white hover:text-teal-600 rounded-full h-11 w-11 p-2 md:p-3 ml-2 md:ml-5 lg:ml-12 md:h-14 md:w-14 2xl:h-16 2xl:w-16' />
                            </a>
                        </div>
                    </div>
                </div>
                {/* Blogs ends here */}

                <FooterIT />
            </div>
        </m.div>
    );
}

export default Home;