import React, { useEffect } from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import { useNavigate } from 'react-router-dom';
import { motion as m } from 'framer-motion';

const WrittenProduction = () => {

    const navigate = useNavigate();

    const handleProjectClick = (projectName) => {
        navigate(`/CaseStudy/${projectName}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
                <HeaderIT />
                <br />
                <div className='px-2 md:px-5 lg:mt-20 2xl:mt-28'>

                    <div className='flex flex-col items-center justify-center my-12 md:my-14'>
                        <div className='h-32 md:h-40 lg:h-48 2xl:h-72 w-full lg:w-[88vw] lg:mx-16 2xl:mx-48 z-0 rounded-xl overflow-hidden'>
                            <img className='h-full w-full' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0a699c4d-3e81-4e13-970c-920a7cea9b00/public" alt="background" /> {/* init_background.webp */}
                        </div>
                        <div className='flex items-center justify-center h-32 md:h-40 lg:h-48 2xl:h-72 -mt-32 md:-mt-40 lg:-mt-48 2xl:-mt-72 z-0'>
                            <p className='text-black text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold'>
                                Written Production
                            </p>
                        </div>
                    </div>

                    <div className='mt-10 2xl:mt-24 mx-4 md:mx-10 lg:mx-36 2xl:mx-72'>
                        <div className='my-5 lg:my-8'>
                            <p>At Init Technology, we understand the critical role that compelling written content plays in
                                engaging audiences, and driving conversions. Our team of skilled writers and content strategists
                                specialises in delivering high-quality written production services that captivate, inform, and
                                inspire your target audience. We carefully choose the right words for what you want to achieve
                                and make sure they match the overall tone of your message.  </p>

                            <p className='my-5'>Below are some of the services that we provide: </p>

                            <ul className='my-5 marker:text-teal-600 list-disc pl-5 space-y-3'>
                                <li>Content Strategy and Planning </li>
                                <li>Blog and Article Writing </li>
                                <li>Website Copywriting  </li>
                                <li>SEO Content Writing </li>
                            </ul>
                        </div>

                        {/* Cards starts from here */}
                        <div className="mt-5 mb-8">
                            <div className="grid grid-cols-2 lg:grid-cols-3 items-center justify-center gap-[3%]">

                                <div className='cursor-pointer' onClick={() => handleProjectClick('OPEN STUDIOS')}>
                                    <div className="bg-white dark:bg-[#252525] p-[3%] lg:p-[5%] lg:py-[10%] rounded-xl shadow-lg hover:shadow-custom hover:scale-105 transition-transform duration-300 ease-in-out">
                                        <img className="" src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b053b87b-508e-41fa-314e-1c1c4f46c800/public" alt="Img" /> {/* init_openStudios_box.webp */}
                                        <div className="mt-[10%] text-center">
                                            <p className="font-semibold">Open Studios</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='cursor-pointer' onClick={() => handleProjectClick('DRESSWALA')}>
                                    <div className="bg-white dark:bg-[#252525] p-[3%] lg:p-[5%] lg:py-[10%] rounded-xl shadow-lg hover:shadow-custom hover:scale-105 transition-transform duration-300 ease-in-out">
                                        <img className="" src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/a3a441bd-ae11-4eb0-1c85-b56e30cd1c00/public" alt="Img" /> {/* init_dresswala_box.webp */}
                                        <div className="mt-[10%] text-center">
                                            <p className="font-semibold">Dresswala</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='cursor-pointer' onClick={() => handleProjectClick('SOHAM VILLAS')}>
                                    <div className="bg-white dark:bg-[#252525] p-[3%] lg:p-[5%] lg:py-[10%] rounded-xl shadow-lg hover:shadow-custom hover:scale-105 transition-transform duration-300 ease-in-out">
                                        <img className="" src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/b3ed5eef-b783-4d50-11e2-88fbaa3d0b00/public" alt="Img" /> {/* init_soham_box.webp */}
                                        <div className="mt-[10%] text-center">
                                            <p className="font-semibold">Soham Villas</p>
                                        </div>
                                    </div>
                                </div>

                                <div className='cursor-pointer' onClick={() => handleProjectClick('RPJ HOTEL')}>
                                    <div className="bg-white dark:bg-[#252525] p-[3%] lg:p-[5%] lg:py-[10%] rounded-xl shadow-lg hover:shadow-custom hover:scale-105 transition-transform duration-300 ease-in-out">
                                        <img className="" src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/37adfae1-d8ba-49c1-9878-b22f21a14500/public" alt="Img" /> {/* init_rpj_box.webp */}
                                        <div className="mt-[10%] text-center">
                                            <p className="font-semibold">Rpj Hotel</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Cards ends here */}

                        <div className='mt-8 lg:mt-14 2xl:mt-20 h-[1px] w-full bg-teal-400'></div>

                        <div className='mt-5'>
                            <div className='text-sm 2xl:text-xl font-medium'>OTHER SERVICES</div>
                            <div className='grid grid-cols-4 text-[12px] lg:text-[16px] 2xl:text-lg font-light lg:font-thin leading-4 mt-2'>
                                <a href='/OurServices_IT/WebsiteDevelopment' className='my-2 hover:underline'>             Website Designing & Development</a>
                                <a href='/OurServices_IT/SoftwareDevelopment' className='my-2 hover:underline'> Software Development           </a>
                                <a href='/OurServices_IT/WrittenProduction' className='my-2 hover:underline'>   Written Production             </a>
                                <a href='/OurServices_IT/Wordpress' className='my-2 hover:underline'>           Wordpress                      </a>
                                <a href='/OurServices_IT/Ecommerce' className='my-2 hover:underline'>           E-Commerce Development         </a>
                                <a href='/OurServices_IT/MobileAppDevelopment' className='my-2 hover:underline'>Mobile App Development         </a>
                                <a href='/OurServices_IT/ManagementServices' className='my-2 hover:underline'>  Management Services            </a>
                                <a href='/OurServices_IT/Seo' className='my-2 hover:underline'>                 SEO                            </a>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterIT />
            </div>
        </m.div>
    );
};

export default WrittenProduction;