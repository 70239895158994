import React from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import {motion as m} from 'framer-motion';

const PrivacyPolicy = () => {
    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
                <HeaderIT />
                <br />
                <div className='px-2 md:px-5 lg:mt-20 2xl:mt-28'>

                    <div className='flex flex-col items-center justify-center my-12 md:my-14'>
                        <div className='h-32 md:h-40 lg:h-48 2xl:h-72 w-full lg:w-[88vw] lg:mx-16 2xl:mx-48 z-0 rounded-xl overflow-hidden'>
                            <img className='h-full w-full' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0a699c4d-3e81-4e13-970c-920a7cea9b00/public" alt="background" /> {/* init_background.webp */}
                        </div>
                        <div className='flex items-center justify-center h-32 md:h-40 lg:h-48 2xl:h-72 -mt-32 md:-mt-40 lg:-mt-48 2xl:-mt-72 z-0'>
                            <p className='text-black text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold'>
                                Privacy & Policy
                            </p>
                        </div>
                    </div>

                    <div className='mt-12 lg:mt-24 mx-4 md:mx-10 lg:mx-36 2xl:mx-72'>
                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>
                                Welcome to Init Technology. Your privacy is critically important to us. This Privacy Policy outlines 
                                how we collect, use, and protect your information when you use our website, services, and products.
                            </p>

                            <p className='font-semibold my-2 md:my-4'>1. Information We Collect</p>
                            <p>• Personal Information: We may collect personal information such as your name, email address, and phone 
                                number, when you register, or interact with our services.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>2. How We Use Your Information</p>
                            <p>• Communication: We may use your contact information to send you updates, newsletters, promotional 
                                materials, and other information related to our services.</p>

                            <p className='mt-2 lg:mt-4'>• Analytics: Non-personal information may be used for analytical purposes to 
                                understand user behaviour and improve our website and services.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>3. Sharing Your Information</p>
                            <p>• Legal Requirements: We may disclose your information if required by law or if we believe such action 
                                is necessary to comply with legal obligations or protect our rights.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>4. Security of Your Information</p>
                            <p>•  We implement industry-standard security measures to protect your information from unauthorised access, 
                                disclosure, alteration, or destruction. </p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>5. Cookies and Tracking Technologies</p>
                            <p>• We use cookies and similar tracking technologies to enhance your experience on our website. You can 
                                control the use of cookies through your browser settings, but disabling cookies may limit your ability 
                                to use certain features of our website.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>6. Children’s Privacy</p>
                            <p>• Our services are not intended for children under the age of 13. We do not knowingly collect personal 
                                information from children under 13. If you believe we have collected such information, please contact 
                                us immediately.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>7. Changes to This Privacy Policy</p>
                            <p>• We may update this Privacy Policy from time to time. We will notify you of any changes by posting the 
                                new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any 
                                changes.</p>
                        </div>

                        <div className='my-5 lg:my-8'>
                            <p className='font-semibold my-2 md:my-4'>8. Contact Us</p>
                            <p className='font-semibold'>• If you have any questions or concerns about these Terms, please contact us at:<br/>
                                <a href='mailto:contact@inittechnology.co' className='underline hover:text-[#008080]'>contact@inittechnology.co</a> 
                                <span className='font-light'> or </span><a href='tel:+919664570369' className='underline hover:text-[#008080]'>+91-96645 70369</a>
                            </p>
                        </div>
                    </div>
                </div>
                <FooterIT />
            </div>
        </m.div>
    );
};

export default PrivacyPolicy;