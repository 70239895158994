import React from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import {motion as m} from 'framer-motion';

const Blog3 = () => {
    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
                <HeaderIT />
                <br />
                <div className='px-2 md:px-5 lg:mt-20 2xl:mt-28'>

                    <div className='bg-[#EAEAEA] dark:bg-[#505050] h-32 md:h-40 lg:h-48 2xl:h-72 lg:mx-16 2xl:mx-48 rounded-xl py-4 md:py-8 lg:py-10 2xl:py-16 mt-14 flex items-center justify-center'>
                        <img className='h-32 md:h-48 lg:h-52 2xl:h-80 mt-12 md:mt-24 lg:mt-24 2xl:mt-40 mx-2 2xl:mx-4' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/4396d098-56fd-4037-772d-ce33bba6de00/public" alt="Tech-trends" /> {/* init_blog3.webp */}
                        <div className='flex flex-col mx-2'>
                            <p className='my-1 lg:my-2 2xl:my-4 text-xl md:text-3xl lg:text-4xl 2xl:text-5xl font-light'>Tech Trends 2024:</p>
                            <p className='my-1 lg:my-2 2xl:my-4 text-[1.3em] md:text-4xl lg:text-5xl 2xl:text-6xl lg:leading font-medium'>
                                How SaaS is changing <br className='hidden lg:block'/> the Software Industry
                            </p>
                        </div>
                    </div>

                    <div className='mt-10 md:mt-20 2xl:mt-32 mx-4 md:mx-10 lg:mx-36 2xl:mx-72'>
                        <div>
                            <p>The way we use software has changed dramatically with the rise of SaaS (Software as a Service). Instead 
                                of buying and installing software on individual computers, businesses and individuals now access 
                                powerful tools through the cloud. SaaS has revolutionized the software industry by offering a more 
                                flexible, scalable, and affordable solution.</p>
                        </div>

                        <div className='my-5 2xl:my-10 bg-teal-400 h-[1px] mx-5'></div>

                        <div className='mt-3 lg:mt-5 2xl:mt-12'>
                            <p className='my-1 lg:my-2 2xl:my-3'>1. Shift towards remote and hybrid work</p>
                            <p className='my-1 lg:my-2 2xl:my-3'>2. Enterprise-level tools</p>
                            <p className='my-1 lg:my-2 2xl:my-3'>3. Scalability</p>

                        </div>

                        <div className='mt-3 lg:mt-5 2xl:mt-12'>
                            <p className='my-6 2xl:my-8'>One of the main advantages of SaaS is its flexibility. Businesses no longer 
                                have to worry about hardware or complex installations. They can subscribe to the software they need, 
                                access it from anywhere, and pay only for what they use. This pay-as-you-go model helps companies save 
                                money and scale effortlessly as they grow.</p>

                            <p className='my-6 2xl:my-8'>SaaS also keeps software up to date without manual updates. Providers handle 
                                updates and maintenance behind the scenes, ensuring businesses always have the latest features and 
                                security patches.</p>

                            <p className='my-6 2xl:my-8'>At Init Technology, we are part of this revolution. We're excited to introduce 
                                two new SaaS products—Quotsync and Waverise Hub. Quotsync helps businesses streamline their quoting 
                                process, making it faster and more efficient. Waverise Hub, on the other hand, offers a comprehensive 
                                solution for project management and collaboration, ideal for teams of any size.</p>

                            <p className='my-6 2xl:my-8'>In addition to these products, we specialize in creating custom solutions 
                                tailored to our clients' needs. Whether you require a unique feature or an entirely new platform, we 
                                can build it from the ground up to meet your business goals.</p>

                            <p className='my-6 2xl:my-8'>In addition to these products, we specialize in creating custom solutions 
                                tailored to our clients' needs. Whether you require a unique feature or an entirely new platform, we 
                                can build it from the ground up to meet your business goals.</p>
                        </div>

                        <div className='mt-8 lg:mt-14 2xl:mt-24'>
                            <p className='font-medium text-sm 2xl:text-xl'>Naju.K</p>
                            <p className='text-sm mt-2 2xl:text-lg'>September 14, 2024 ∙ 4 mins read</p>
                        </div>
                    </div>
                </div>
                <FooterIT />
            </div>
        </m.div>
    );
};

export default Blog3;