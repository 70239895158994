import React,{useEffect} from "react";
import './SplashScreenWH.css';

const SplashScreenWH = () =>{
    useEffect(() => {
        
        var splashScreen = document.querySelector('.splash_WH');
        splashScreen.addEventListener('click', () => {
          splashScreen.style.opacity = 0;
          setTimeout(() => {
            splashScreen.classList.add('hidden_WH');
          }, 610);
        });
      }, []); 
  
return(
    <div>
        <div className="splash_WH">
            <h1 className = "splash-header_WH"><img className="logo_SplashScreen_WH" src="../assets/waverise_hub/waverise_logo.png" alt="logo"></img></h1>
        </div>
    </div>
)
};

export default SplashScreenWH;