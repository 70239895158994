import React, { useState } from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import {motion as m} from 'framer-motion';
import { API_URL } from '../../../Config';

const Careers = () => {

    const [isPopup_thankYou, setPopup_thankYou] = useState(false);

    const openPopup_thankYou = () => {
        setPopup_thankYou(true);
    };

    const closePopup_thankYou = () => {
        setPopup_thankYou(false);
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        role: '',
        resume: null,
    });
    const [contactError, setContactError] = useState('');
    const [contactSuccess, setContactSuccess] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'phone') {
            const cleanedValue = value.replace(/\D/g, '');
            const limitedValue = cleanedValue.slice(0, 10);
            setFormData((prevState) => ({
                ...prevState,
                [name]: limitedValue
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const validateEmailInForm = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^\d{10}$/;
        return phoneRegex.test(phone);
    };

    const validateContactForm = () => {
        let formErrors = {};

        if (!formData.firstName.trim()) {
            formErrors.firstName = "First name is required.";
        }
        if (!formData.lastName.trim()) {
            formErrors.lastName = "Last name is required.";
        }
        if (!validateEmailInForm(formData.email)) {
            formErrors.email = "Please enter a valid email address.";
        }
        if (!validatePhoneNumber(formData.phone)) {
            formErrors.phone = "Please enter a valid phone number (10 digits).";
        }
        if (!formData.role.trim()) {
            formErrors.role = "Role is required.";
        }
        if (Object.keys(formErrors).length === 0 &&
            (!formData.firstName.trim() ||
                !formData.lastName.trim() ||
                !formData.email ||
                !formData.phone )) {
            return { general: "Please fill out all fields." };
        }

        return formErrors;
    };

    const handleContactSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateContactForm();
    
        if (formErrors.general) {
            setContactError(formErrors.general);
            setContactSuccess('');
            console.log("Form submission error:", formErrors.general);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
                role: '',
                resume: null 
            });
            setTimeout(() => {
                setContactError('');
            }, 8000);
        } else if (Object.keys(formErrors).length > 0) {
            setContactError(Object.values(formErrors).join(' '));
            setContactSuccess('');
            console.log("Form submission errors:", formErrors);
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
                role: '',
                resume: null 
            });
            setTimeout(() => {
                setContactError('');
            }, 8000);
        } else {
            setContactError('');
            setContactSuccess('Your message has been sent successfully!');
    
            const formDataToSend = new FormData();
            formDataToSend.append('firstName', formData.firstName);
            formDataToSend.append('lastName', formData.lastName);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('message', formData.message);
            formDataToSend.append('role', formData.role);
            if (formData.resume) {
                formDataToSend.append('resume', formData.resume);
            }
    
            try {
                const response = await fetch(API_URL+'/send-email-careers', {
                    method: 'POST',
                    body: formDataToSend,
                });
    
                if (response.ok) {
                    console.log('Email sent successfully');
                    openPopup_thankYou();
                } else {
                    console.log('Failed to send email');
                }
            } catch (error) {
                console.log('Error:', error);
            }
    
            setFormData({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                message: '',
                role: '',
                resume: null  
            });
            setTimeout(() => {
                setContactSuccess('');
            }, 20000);
        }
    };
    
    // const [isUploading, setIsUploading] = useState(false); 

    const handleFileChange = (e) => {
        const file = e.target.files[0]; 
        if (file) {
            // setIsUploading(true); 
            if (validateFile(file)) {
                setFormData((prevState) => ({
                    ...prevState,
                    resume: file  
                }));
                // setIsUploading(false); 
            } else {
                setContactError("Please upload a valid file (PNG, JPG, JPEG, or PDF).");
                // setIsUploading(false);
            }
        }
    };
    
    const validateFile = (file) => {
        const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
        return allowedTypes.includes(file.type);
    };

    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
                <HeaderIT />
                <br />
                <form className='px-2 md:px-5 lg:mt-20 2xl:mt-28' onSubmit={handleContactSubmit} required>

                    <div className='flex flex-col items-center justify-center my-12 md:my-14'>
                        <div className='h-32 md:h-40 lg:h-48 2xl:h-72 w-full lg:w-[88vw] lg:mx-16 2xl:mx-48 z-0 rounded-xl overflow-hidden'>
                            <img className='h-full w-full' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/0a699c4d-3e81-4e13-970c-920a7cea9b00/public" alt="background" /> {/* init_background.webp */}
                        </div>
                        <div className='flex items-center justify-center h-32 md:h-40 lg:h-48 2xl:h-72 -mt-32 md:-mt-40 lg:-mt-48 2xl:-mt-72 z-0'>
                            <p className='text-black text-center text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl font-semibold'>
                                Careers
                            </p>
                        </div>
                    </div>

                    <div className='mt-5 lg:mt-8 2xl:mt-32 mx-4 md:mx-10 lg:mx-36 2xl:mx-72'>
                        <div className='grid grid-cols-2'>
                            <div className='px-4 lg:px-10'>
                                <div className='my-12 lg:my-14 2xl:my-28'>
                                    <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>First Name*</p>
                                    <input 
                                        className='w-full mt-1 md:mt-2 p-1 md:p-2 outline-none bg-transparent' 
                                        type="text" 
                                        name="firstName"
                                        placeholder='Enter first name'
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        tabIndex="1"
                                        required/>
                                    <div className='mt-1 h-[2px] bg-[#008080]'></div>
                                </div>

                                <div className='my-12 lg:my-14 2xl:my-28'>
                                    <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>Email*</p>
                                    <input 
                                        className='w-full mt-1 md:mt-2 p-1 md:p-2 outline-none bg-transparent' 
                                        type="text" 
                                        name="email"
                                        placeholder='Enter email'
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        tabIndex="3"
                                        required />
                                    <div className='mt-1 h-[2px] bg-[#008080]'></div>
                                </div>

                                <div className='my-12 lg:my-14 2xl:my-28'>
                                    <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>Role you want to apply for*</p>
                                    <input 
                                        className='w-full mt-1 md:mt-2 p-1 md:p-2 outline-none bg-transparent' 
                                        type="text"
                                        name="role"  
                                        placeholder='Enter role'
                                        value={formData.role}  
                                        onChange={handleInputChange} 
                                        tabIndex="5"
                                        required />
                                    <div className='mt-1 h-[2px] bg-[#008080]'></div>
                                </div>
                            </div>

                            <div className='px-4 lg:px-10'>
                                <div className='my-12 lg:my-14 2xl:my-28'>
                                    <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>Last Name*</p>
                                    <input 
                                        className='w-full mt-1 md:mt-2 p-1 md:p-2 outline-none bg-transparent' 
                                        type="text" 
                                        name="lastName"
                                        placeholder='Enter last name'
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        tabIndex="2"
                                        required />
                                    <div className='mt-1 h-[2px] bg-[#008080]'></div>
                                </div>

                                <div className='my-12 lg:my-14 2xl:my-28'>
                                    <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>Phone No.*</p>
                                    <input 
                                        className='w-full mt-1 md:mt-2 p-1 md:p-2 outline-none bg-transparent' 
                                        type="text" 
                                        name="phone"
                                        placeholder='Enter phone no.'
                                        pattern="\d{10}"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        tabIndex="4"
                                        required />
                                    <div className='mt-1 h-[2px] bg-[#008080]'></div>
                                </div>

                                {/* <div className='mt-12 lg:my-14 2xl:my-28'>
                                    <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>Upload your resume here*</p>
                                    <div className='h-7 lg:h-10'>
                                        <a href="/"><img className="h-6 lg:h-8 mt-3 ml-1 2xl:mt-5" src="../assets/cloud_icon.webp" alt="cloud"></img></a>
                                    </div>
                                    <div className='mt-1 md:mt-5 lg:mt-1 h-[2px] bg-[#008080]'></div>
                                </div> */}

                                <div className='my-12 lg:my-14 2xl:my-28'>
                                    <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>Upload your resume here*</p>
                                    <div className='flex'>
                                    <img className="h-6 lg:h-8 mt-3 ml-1 2xl:mt-5" src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/41d007e0-70ba-43ca-16dd-29d8648eaf00/public" alt="cloud"/> {/* init_cloud_icon.webp */}
                                    <input
                                        className='w-full p-1 md:p-2 outline-none bg-transparent cursor-pointer'
                                        type="file"
                                        name="resume"  
                                        accept=".png, .jpg, .jpeg, .pdf"  
                                        onChange={handleFileChange}  
                                        tabIndex="6"
                                        required />  
                                        {/* {isUploading && (
                                            <div className="loader ml-2 text-red-500">Uploading...</div>
                                        )} */}
                                    </div>
                                    <div className='mt-1 md:mt-5 lg:mt-1 h-[2px] bg-[#008080]'></div>
                                </div>

                            </div>
                        </div>

                        <div className='px-4 lg:px-10 my-2'>
                            <p className='text-sm 2xl:text-lg font-semibold text-gray-500'>Message (optional)</p>
                            <textarea 
                                className='w-full mt-2 md:mt-2 p-1 md:p-2 outline-none bg-transparent' 
                                type="text" 
                                name="message"
                                placeholder='Enter message'
                                value={formData.message}
                                onChange={handleInputChange}
                                tabIndex="7" />
                            <div className='mt-1 h-[2px] bg-[#008080]'></div>
                        </div>

                        <div className='flex justify-end mt-10 2xl:mt-72 mr-3 lg:mr-7 2xl:mr-12'>
                            <button className="btn_careers" onSubmit={handleContactSubmit} required>
                                <span className="transition_btn_careers"></span>
                                <span className="gradient_btn_careers"></span>
                                <span className="label_btn_careers">Send my resume!</span>
                            </button>
                        </div>

                        {contactError && <p className='text-red-500 mt-2 tex-center'>{contactError}</p>}
                        {contactSuccess && <p className='text-green-500 mt-2 text-center'>{contactSuccess}</p>}
                                
                        {isPopup_thankYou && (
                            <>
                                <div className="overlay_Careers" onClick={closePopup_thankYou}></div>
                                <div className="popup_form_Careers">
                                    <span className="close_Careers" onClick={closePopup_thankYou}>&times;</span>
                                    <form>
                                        <div className='text-black'>
                                            <p className='text-lg md:text-2xl lg:text-lg 2xl:text-3xl font-semibold'>Thank you for your response!</p>
                                            <p className='mt-[2%]'>Your resume has been successfully sent.</p>

                                            <div className='flex justify-end mt-[3%]'>
                                                <button className="btn_careers" onClick={closePopup_thankYou}>
                                                    <span className="transition_btn_careers"></span>
                                                    <span className="gradient_btn_careers"></span>
                                                    <span className="label_btn_careers">Done</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </>
                        )}
                    </div>
                </form>
                <FooterIT />
            </div>
        </m.div>
    );
};

export default Careers;