import React from 'react';
import HeaderIT from '../Header_IT/Header';
import FooterIT from '../Footer_IT/Footer';
import {motion as m} from 'framer-motion';

const Blog2 = () => {
    return (
        <m.div
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0.5, 1] }}
            transition={{ duration: 0.9, ease: 'easeIn' }}
            style={{ willChange: 'opacity' }}
        >
            <div className='bg-white dark:bg-black dark:text-white text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
                <HeaderIT />
                <br />
                <div className='px-2 md:px-5 lg:mt-20 2xl:mt-28'>

                    <div className='bg-[#EAEAEA] dark:bg-[#505050] h-32 md:h-40 lg:h-48 2xl:h-72 lg:mx-16 2xl:mx-48 rounded-xl py-4 md:py-8 lg:py-10 2xl:py-16 mt-14 flex items-center justify-center'>
                        <img className='h-32 md:h-48 lg:h-52 2xl:h-80 mt-12 md:mt-24 lg:mt-24 2xl:mt-40 mx-2 2xl:mx-4' src="https://imagedelivery.net/JqAydcRLXyliJTMOjPllJQ/9fcb1211-c61a-4fce-f02a-ae0883e55d00/public" alt="Tech-trends" /> {/* init_blog2.webp */}
                        <div className='flex flex-col mx-2'>
                            <p className='my-1 lg:my-2 2xl:my-4 text-xl md:text-3xl lg:text-4xl 2xl:text-5xl font-light'>Tech Trends 2024:</p>
                            <p className='my-1 lg:my-2 2xl:my-4 text-[1.3em] md:text-4xl lg:text-5xl 2xl:text-6xl lg:leading font-medium'>
                                Why React is the Best Choice<br className='hidden lg:block'/> for Modern Web Apps
                            </p>
                        </div>
                    </div>

                    <div className='mt-10 md:mt-20 2xl:mt-32 mx-4 md:mx-10 lg:mx-36 2xl:mx-72'>
                        <div>
                            <p>React has quickly become one of the most popular tools for building websites and web apps.</p>
                            <p className='mt-3 lg:mt-5 2xl:mt-12'>It was created by Facebook and is now used by companies worldwide, 
                                including Init Technology because it’s fast, flexible, and easy to work with.</p>
                        </div>

                        <div className='my-5 2xl:my-10 bg-teal-400 h-[1px] mx-5'></div>

                        <div className='mt-3 lg:mt-5 2xl:mt-12'>
                            <p className='my-1 lg:my-2 2xl:my-3'>1. Easy-to-Use Components</p>
                            <p className='my-1 lg:my-2 2xl:my-3'>2. Fast Loading with the Virtual DOM</p>
                            <p className='my-1 lg:my-2 2xl:my-3'>3. ⁠Big Community and Lots of Resources</p>
                            <p className='my-1 lg:my-2 2xl:my-3'>4. ⁠Flexibility in Integrations</p>
                            <p className='my-1 lg:my-2 2xl:my-3'>5. ⁠Optimised for SEO</p>
                            <p className='my-1 lg:my-2 2xl:my-3'>6. ⁠React Native for Mobile Apps</p>

                        </div>

                        <div className='mt-3 lg:mt-5 2xl:mt-12'>
                            <p className='my-6 2xl:my-8'>React has a huge community of developers who are constantly contributing to 
                                its growth. This means that if you run into a problem or need a specific tool, there’s likely already 
                                a solution or plugin available.</p>

                            <p className='my-6 2xl:my-8'>Another big advantage of React is its performance, made possible by something 
                                called the Virtual DOM. In traditional web development, the entire web page would need to reload 
                                whenever there was a change, which could slow things down. But with React, only the parts of the page 
                                that actually need to be updated get changed, thanks to the Virtual DOM. This means faster load times 
                                and a smoother experience for users, especially on complex, data-heavy websites.</p>

                            <p className='my-6 2xl:my-8'>React also boasts a huge community of developers who constantly contribute to 
                                its ecosystem. This massive community is a significant asset because it provides a wealth of resources. 
                                If you run into any challenges while developing with React, chances are someone has already solved the 
                                issue. Plus, there’s always ongoing development and improvements, so React remains at the forefront of 
                                modern web development.</p>

                            <p className='my-6 2xl:my-8'>One of the reasons React appeals to businesses, beyond its technical merits, 
                                is its SEO potential. While React is mainly a client-side library, it can be optimized for search engines 
                                using techniques like server-side rendering. This is crucial in today’s digital world, where being 
                                search-engine friendly can make or break a website’s visibility. By ensuring that React apps can be 
                                indexed properly, companies can benefit from better SEO performance without sacrificing functionality.</p>

                            <p className='my-6 2xl:my-8'>React has earned its reputation as the best choice for building modern web 
                                applications. Its component-based structure, performance through the Virtual DOM, robust community, and 
                                ability to work seamlessly across both web and mobile platforms make it a versatile and powerful tool 
                                for developers and businesses alike. Whether you're building a simple website or a complex, scalable app, 
                                React provides the tools to do so efficiently and effectively.</p>
                        </div>

                        <div className='mt-8 lg:mt-14 2xl:mt-24'>
                            <p className='font-medium text-sm 2xl:text-xl'>Naju.K</p>
                            <p className='text-sm mt-2 2xl:text-lg'>September 10, 2024 ∙ 6 mins read</p>
                        </div>
                    </div>
                </div>
                <FooterIT />
            </div>
        </m.div>
    );
};

export default Blog2;