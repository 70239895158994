import React, { useState } from 'react';
import { InlineWidget } from "react-calendly";

const HeaderWH = () => {

    const [isChanged, setIsChanged] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleToggleChange = () => {
        setIsChanged(prevState => !prevState);
        setMenuOpen(!menuOpen);
    };

    const [isIsFormOpenFor_bookCall, setIsFormOpenFor_bookCall] = useState(false);

    const openForm1 = () => {
        setIsFormOpenFor_bookCall(true);
    };

    const closeForm1 = () => {
        setIsFormOpenFor_bookCall(false);
    };

    return (
        <div className='text-[16px] leading-7 md:text-xl md:leading-8 lg:text-[16px] lg:leading-7 2xl:text-2xl 2xl:leading-10'>
            <div className='bg-white flex flex-row h-16 w-full p-3 px-4 2xl:px-6 items-center fixed top-0 z-[5] md:h-20 2xl:h-28'>
                <div className=''>
                    <a href='/'>
                        <img className='h-14 w-32 md:w-28 md:h-16 2xl:h-24 2xl:w-56' src="../assets/waverise_hub/waverise_logo.png" alt="Waverise-logo" />
                    </a>
                </div>

                <div className='hidden lg:flex items-center ml-auto'>
                    <div className='mx-5 2xl:mx-8 hover:text-teal-600 text-md 2xl:text-2xl'>
                        <a href="/">Our services</a>
                    </div>

                    <div className='mx-5 2xl:mx-8 hover:text-teal-600 text-md 2xl:text-2xl'>
                        <a href="/">How it Works</a>
                    </div>

                    <div className='mx-5 2xl:mx-8 hover:text-teal-600 text-md 2xl:text-2xl'>
                        <a href="/">Our Products</a>
                    </div>

                    <div className='mx-5 2xl:mx-8 hover:text-teal-600 text-md 2xl:text-2xl'>
                        <a href="/">About Us</a>
                    </div>

                    <div className='mx-5 2xl:mx-8 hover:text-teal-600 text-md 2xl:text-2xl'>
                        <a href="/">Blogs</a>
                    </div>

                    <div className='mx-5 2xl:mx-8 hover:text-teal-600 text-md 2xl:text-2xl'>
                        <a href="#ContactUs">Contact Us</a>
                    </div>

                    <div className='ml-3 flex'>
                        <button className='flex items-center text-md 2xl:text-2xl bg-[#C2F8ED] p-2 px-4 rounded-md shadow-md hover:shadow-custom' onClick={openForm1}>
                            Book Demo
                            <img className='h-4 pt-1 pl-2 2xl:h-5 2xl:mt-1' src="../assets/right_arrow.png" alt="Img" />
                        </button>
                    </div>
                </div>

                <div className='lg:hidden items-center flex ml-auto  z-10'>
                    <div className={isChanged ? "change" : ""} onClick={handleToggleChange}>
                        <div className="bar1"></div>
                        <div className="bar2"></div>
                        <div className="bar3"></div>
                    </div>
                </div>

                <div className='lg:hidden fixed bg-black h-100 pr-16 pl-6' style={{ right: menuOpen ? 0 : '-450px', transition: 'right 0.5s ease-in-out' }}>
                    <div className='mt-[270vw] md:mt-[240vw] pb-[150vw] leading-[4] md:leading-[5]'>
                        <a href="/" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Our Services</a><br />
                        <a href="/" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Our Products</a><br />
                        <a href="/" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>How it Works</a><br />
                        <a href="/Blogs" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Blogs</a><br />
                        <a href="/" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>About Us</a><br />
                        <a href="#ContactUs" className='text-white text-lg md:text-2xl' onClick={handleToggleChange}>Contact Us</a><br />
                        <button className='bg-[#C2F8ED] p-2 rounded-md text-teal-600 mt-4 text-lg md:text-2xl' onClick={openForm1} >Book Demo</button>
                    </div>
                </div>

                {isIsFormOpenFor_bookCall && (
                    <>
                        <div className="block fixed h-[400vw] w-[110vw] ml-[-20px] bg-black opacity-60" onClick={closeForm1} ></div>
                        <div className="fixed block h-[650px] w-[90vw] p-4 lg:p-0 lg:w-[73vw] 2xl:w-[40vw] mx-2 mt-[710px] lg:mx-48 lg:mt-[680px] 2xl:mx-[30vw] 2xl:mt-[1000px] bg-white border border-gray-300 rounded-lg">
                            <span className="absolute right-1 lg:right-2 top-0 cursor-pointer text-2xl" onClick={closeForm1}>&times;</span>
                            <form>
                                <div>
                                    <InlineWidget
                                        url="https://calendly.com/contact-inittechnology/30min"
                                        rootElement={document.getElementById("root")}
                                        text="Book a Call"
                                        textColor="#ffffff"
                                        color="rgb(31, 151, 151)"
                                    />
                                </div>
                            </form>
                        </div>
                    </>
                )}

            </div>
        </div>
    );
};

export default HeaderWH;