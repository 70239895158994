import React from 'react';

const FooterWH = () => {
    return (
        <div>
            this is footer
        </div>
    );
};

export default FooterWH;